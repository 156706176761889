import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/react"

export default () => (
  <StaticQuery
    query={graphql`
      query imageDeroulementEnQuery {
        file(relativePath: { eq: "deroulement-consultation.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        fluid={data.file.childImageSharp.fluid}
        alt="Procedure for an orthopedic surgery consultation with Dr Philippe Roure in his office in Paris 7th arrondissement"
        css={css`
          width: 100%;
          @media (max-width: 678px) {
            display: none;
          }
        `}
      />
    )}
  />
)
