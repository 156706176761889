import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/react"

export default () => (
  <StaticQuery
    query={graphql`
      query vivaltoquery {
        file(relativePath: { eq: "vivalto.png" }) {
          childImageSharp {
            resize(width: 1000, height: 500) {
              src
            }
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={(data) => (
      <Img
        fluid={data.file.childImageSharp.fluid}
        alt="CHP Montgardé du groupe Vivalto Santé dans les Yvelines"
        css={css`
          width: 320px;
          float: left;
          margin-right: 15px;
        `}
      />
    )}
  />
)
